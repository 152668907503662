// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { APIRequest, Post, DashboardAccount, IBrandDetails } from "./interfaces";
import { Message } from "../../../framework/src/Message";
import { getToken, setToken } from "../../../components/src/AuthService";
import { getStorageData } from "../../../framework/src/Utilities";
import React, { createRef } from "react";

export const configJSON = require("./config.js");

interface BrandAttributesHome {
  activated: boolean;
  country_code: string;
  email: string;
  full_name: string | null;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  pin: number;
  sign_up_step: number;
  business_website: string;
  zip_code: string;
  social_phone_number: string;
  is_first_login: boolean;
  profile_pic: {
    url: string;
  } | null;
  cover_photo: {
    url: string;
  } | null;
  follows: {
    followers: string;
    followings: string;
  };
  subscription_paused: boolean;
}
interface BrandHome {
  id: string;
  type: string;
  attributes: BrandAttributesHome;
}
interface AccountAttributes {
  activated: boolean;
  country_code: string | null;
  email: string | null;
  full_name: string;
  first_name: string | null;
  full_phone_number: string;
  last_name: string | null;
  phone_number: string | null;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  pin: number;
  sign_up_step: number;
  business_website: string | null;
  zip_code: string | null;
  social_phone_number: string | null;
  is_first_login: boolean;
  profile_pic: { url: string } | null;
  cover_photo: string | null;
  follows: {
    followers: string;
    followings: string;
  };
  subscription_paused: boolean | null;
}

interface AccountData {
  data: {  
    id: string;
    type: string;
    attributes: AccountAttributes;
  };
}

interface ReplyAttributes {
  id: number;
  account_id: number;
  commentable_id: number;
  commentable_type: string;
  comment: string;
  visibility: string;
  likes_count: number;
  comments_count: number;
  created_at: string;
  archived: boolean;
  account: AccountData;
  replies: {
    data: ReplyAttributes[];
  };
  already_liked: boolean;
  files: null | any; 
}

interface CommentAttributes {
  id: number;
  account_id: number;
  commentable_id: number;
  commentable_type: string;
  comment: string;
  visibility: string;
  likes_count: number;
  comments_count: number;
  created_at: string;
  archived: boolean;
  account: AccountData; 
  replies: {
    data: CommentData[];
  };
  already_liked: boolean;
  files: null | any; 
}

interface CommentData {
  id: string;
  type: string;
  attributes: CommentAttributes;
}

interface CommentResponse {
  data: CommentData[];
  meta: {
    last_page: boolean;
  };
}



export interface Props { 
  navigation: any;
  id: string;
  classes?: any;
  hidePostApicall?: (values: any) => void;
  banUserApiCall: (values: any) => void | null;
  postCommentApiCall: (values: any) => void;
  showAlert?: boolean;
  showSuccess?: string;
  errorAlertMessage?: boolean;
  dashboardApicall?: Function;
  deleteCommentApiCall?: (id: number) => void;
  archiveCommentApiCall?: (values: any) => void;
  revertArchiveCommentApiCall?: (id: number) => void;
  achiveId?: number;
  disableClass?: boolean;


}


interface S {
  openDialog:boolean,
  isLoading: boolean;
  account?: DashboardAccount;
  posts: Post[];
  top6: IBrandDetails[];
  showSucess: string;
  isShowAlert: boolean;
  errorAlertMessage: boolean;
  achiveId: any;
  scrlltoPost: number;
  notificationData: any;
  brandsIFollowHome: BrandHome[];
  suggestedBrandsHome: BrandHome[];
  euHomePageNo: number,
  euHomeTotalPages: number,
  isEUHomePostLoading: boolean
  videoRefsHome: React.RefObject<HTMLVideoElement>[];
  showCommentBox:boolean;
  commentText:string;
  commentBoxes: { [postId: string]: boolean }; 
  commentTexts: { [postId: string]: string };   
  allCommentsData: CommentResponse | null;
  actionModalOpen: boolean,
    anchorEl: HTMLElement | null;  
    selectedCommentId: number | null | string;  
    selectedPostId:number | null | string | any;
    repliesVisible: any,
    replyTexts: Record<string, string>;
  replyingTo: string | null; 
  deleteMessage:string,
  commentCreated:string,
  snackbarOpen:boolean,
  selectedFile: File | null;
 
  currentCommentsPostId: number | string | null;
  localCommentText:string;
  replyText: string;
  isLiked:boolean;
  likedComments: { [key: string]: boolean };
  commentLikesCount: { [key: string]: number };
  uploadedImage: string | null;
  uploadedImageFile: File | null;
  uploadedReplyImage: string | null;
  uploadedReplyImageFile: File | null
  dialogMessage:string,
  openSnackbar: boolean,
  snackbarMessage: string,
  snackbarSeverity: any,
  replyToCommentId: any;
  uploadedReplyImages: { [key: number]: string | null };
  hasScrolled: boolean;
  isDashboard: boolean
}
interface SS {
  id: any;
}

export default class EuDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  dashboardApiCallId: string = "";
  tokenUpdateApiCallId: string = "";
  addLikePostApiCallId: string = "";
  addLikeCommentCallId:string= "";
  addCommentPostApiCallId:string = "";
  addCommentReplyPostApiCallId:string = ""
  addCommentReplyPostCallId:string = ""
  getPostCommentApiCallId:string ="";
  deletePostCommentApiCallId:string = ""
  likeCommentApiCallId:string = ""
  UndolikeCommentApiCallId:string = ""
  

  euHomeBrandsApiCallId: string = "";
  followBrandApiCallId: string = "";
  observerHome: IntersectionObserver | null = null;
  homeRef: React.RefObject<HTMLDivElement>;
  fileInputReply = createRef<HTMLInputElement>(); 
  fileInput = createRef<HTMLInputElement>(); 
  

 
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.ConnectTOSidebar),
      getName(MessageEnum.DashboardRedirection)
    ];

    this.state = {
      isLoading: false,
      openDialog:false,
      account: undefined,
      posts: [],
      top6: [],
      showSucess: "",
      isShowAlert: false,
      errorAlertMessage: false,
      achiveId: 0,
      scrlltoPost: 0,
      notificationData: {},
      brandsIFollowHome: [],
      suggestedBrandsHome: [],
      euHomePageNo: 1,
      euHomeTotalPages: 0,
      isEUHomePostLoading: true,
      videoRefsHome: [],
      showCommentBox:false,
      commentText:"",
      commentBoxes: {},  
      commentTexts: {},  
     
      allCommentsData: { data: [], meta: { last_page: false } },
      actionModalOpen: false,
      anchorEl: null,
      selectedCommentId: null,
      selectedPostId:null,
      repliesVisible:{},
      replyTexts: {}, 
      replyingTo: null, 
      deleteMessage:"",
      commentCreated:"",
      snackbarOpen:false,
      selectedFile:null,
      currentCommentsPostId: null, 
      localCommentText:"",
      replyToCommentId: null,
      replyText: '',
      isLiked:false,
      likedComments: {},
      commentLikesCount: {},
      uploadedImage: null,
      uploadedImageFile:null,
      uploadedReplyImage:null,
      uploadedReplyImageFile:null,
      dialogMessage:"",
      openSnackbar: false,
      snackbarMessage: '',
      snackbarSeverity: 'success' as 'success' | 'error' | 'warning' | 'info',
      uploadedReplyImages: {},
      hasScrolled: false,
      isDashboard: window.location.pathname === "/EuDashboard" ? true : false
    };
    this.homeRef = React.createRef();
    this.fileInputReply = React.createRef();
    this.fileInput = React.createRef()
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getDashboardData();
    this.getSuggestedAndFollowBrandHome();
    const mainContainer = document.querySelector('[data-test-id="eu-home-post-wrapper"]');
    if (mainContainer) {
      mainContainer.addEventListener('scroll', this.handleInitialScroll);
    }
  }

  handleInitialScroll = () => {
    const scrollTop = this.homeRef.current?.parentElement?.scrollTop || 0;
    if (!this.state.hasScrolled) {
      this.setState({ hasScrolled: true }, () => {
        window.scrollTo({
          top: 190, 
          behavior: 'smooth'
        });
      });
    }
    else if (this.state.hasScrolled && scrollTop === 0) {
      this.setState({ hasScrolled: false });
    }
  };

  async componentWillUnmount() {
    const mainContainer = document.querySelector('[data-test-id="eu-home-post-wrapper"]');
    if (mainContainer) {
      mainContainer.removeEventListener('scroll', this.handleInitialScroll);
    }
  }

  componentDidUpdate(
    _prevProps: Readonly<Props>,
    prevState: Readonly<S>
  ): void {
    if (
      this.state.account !== prevState.account &&
      window.localStorage.getItem("rememberMe")
    ) {
      this.updateToken();
    }

    if(prevState.euHomePageNo !== this.state.euHomePageNo && this.state.euHomePageNo <= this.state.euHomeTotalPages) {
      this.getDashboardData()
    }

    if (prevState.posts !== this.state.posts) {
      this.initIntersectionObvEuHome()
      this.setState({videoRefsHome : this.state.posts.map(() => createRef<HTMLVideoElement>())});
    }

  }
  handleFileInputClick = () => {
    this.fileInputReply.current?.click();
  };
  handleFileInputClick1 = () => {
    this.fileInput.current?.click();
  };

  handleSum = (number1:number,number2:number) => {
    return number1 + number2
  }

  initIntersectionObvEuHome () {
    if (this.observerHome) {
      this.observerHome.disconnect();
    }
    
    setTimeout(() => {
      this.observerHome = new IntersectionObserver(
        (entriesPost) => {
          entriesPost.forEach((entryPost) => {
            const videoPost = entryPost.target as HTMLVideoElement;
            if (entryPost.isIntersecting) {
              videoPost.play();
            } else {
              videoPost.pause();
            }
          });
        },
        { threshold: 0.5 }
      );
      
      this.state.videoRefsHome.forEach((refVid) => {
        if (refVid.current) {
          this.observerHome?.observe(refVid.current);
        }
      });
    }, 0); 

    
  }
  handleCloseSnackbar = () => {
    this.setState({ openSnackbar: false });
  };
  
  toggleCommentBox = (postId: number | string | any) => {
    this.setState(
      (prevState) => ({
        currentCommentsPostId: prevState.currentCommentsPostId === postId ? null : postId,
        allCommentsData: prevState.currentCommentsPostId === postId ? prevState.allCommentsData : null 
      }),
      () => {
        if (this.state.currentCommentsPostId === postId) {
          this.getCommentsData(postId);
        }
      }
    );
  };

  cancelUploadReplyImage = () => {
     this.setState({ uploadedReplyImage: null, uploadedReplyImageFile: null })
  }
toggleRepliesVisibility = (commentId: string | number) => {
  this.setState((prevState) => ({
    repliesVisible: {
      ...prevState.repliesVisible,
      [commentId]: !prevState.repliesVisible[commentId], 
    },
  }));
};

handleReplyClick = (commentId: string | number) => {
  this.setState({ replyToCommentId: commentId, replyText: '' });
}

handleReplyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ replyText: event.target.value });
  };


openActionPopover = (event: React.MouseEvent<HTMLButtonElement>,postId:number|string, commentId: number | string) => {
  this.setState({
    anchorEl: event.currentTarget,
    selectedCommentId: commentId,
    selectedPostId: postId,
  });
};

 timeAgo = (dateString: string): string => {
  const now = new Date();
  const date = new Date(dateString);
  
  const seconds = Math.floor((+now - +date) / 1000);
  
  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) return `${interval} year${interval === 1 ? '' : 's'} ago`;

  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) return `${interval} month${interval === 1 ? '' : 's'} ago`;

  interval = Math.floor(seconds / 86400);
  if (interval >= 1) return `${interval} day${interval === 1 ? '' : 's'} ago`;

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) return `${interval} hour${interval === 1 ? '' : 's'} ago`;

  interval = Math.floor(seconds / 60);
  if (interval >= 1) return `${interval} minute${interval === 1 ? '' : 's'} ago`;

  return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
};

handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0]; 
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        uploadedImage: reader.result as string,
        uploadedImageFile: file, 
      });
    };
    reader.readAsDataURL(file);
  }
};
handleReplyImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0];
  if (file) {
      const imageUrl = URL.createObjectURL(file);
      this.setState({
        uploadedReplyImage: imageUrl,
        uploadedReplyImageFile: file,
      });
  }
};





closeActionPopover = () => {
  this.setState({
    anchorEl: null,
    selectedCommentId: null,
  });
};


toggleReplyInput = (commentId: string) => {
  this.setState((prevState) => ({
    replyingTo: prevState.replyingTo === commentId ? null : commentId, 
  }));
};


handleDelete = () => {
  const { selectedCommentId ,selectedPostId} = this.state;

  if (selectedCommentId !== null) {
    const token = window.localStorage.getItem("EUAuthToken");
    if (token) {
      this.deletePostCommentApiCallId = this.makeRequest({
        endpoint: `bx_block_comments/comments/${selectedCommentId}`,
        method: 'DELETE',
        headers: {
          "Content-Type": 'application/json',
          token,
        },
      });
    
    }
  }
 setTimeout(() => {
  this.getCommentsData(selectedPostId)
 },1000)
};



handleLocalCommentTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ localCommentText: event.target.value });
};

async receive(_from: string, message: Message) {
  const apiRequestCallId = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );
  const responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

  if (!responseJson) return;

  
  this.handlePostResponses(apiRequestCallId, responseJson);
  this.handleCommentResponses(apiRequestCallId, responseJson);
  this.handleBrandResponses(apiRequestCallId, responseJson);
  this.handleTokenAndFollowResponses(apiRequestCallId, responseJson);
}

handlePostResponses(apiRequestCallId: string, responseJson: any) {
  if (apiRequestCallId === this.dashboardApiCallId) {
    const { posts: { data }, pagination } = responseJson;
    const { total_pages } = pagination;
    this.setState(prevState => ({
      posts: [...prevState.posts, ...data],
      isEUHomePostLoading: false,
      euHomeTotalPages: total_pages
    }));
  }
}


handleCommentResponses(apiRequestCallId: string, responseJson: any) {
  if (apiRequestCallId === this.getPostCommentApiCallId) {
    this.setState({ allCommentsData: responseJson });
  }

  if (apiRequestCallId === this.deletePostCommentApiCallId) {
    this.displaySnackbar(responseJson.message, 'success');
  }

  if (apiRequestCallId === this.addCommentPostApiCallId) {
    const message = responseJson.errors ? responseJson.errors[0].comment : responseJson.meta.message;
    const severity = responseJson.errors ? 'error' : 'success';
    this.displaySnackbar(message, severity);
  }

  if (apiRequestCallId === this.addCommentReplyPostApiCallId) {
    const message = responseJson.errors ? responseJson.errors[0].comment : responseJson.meta.message;
    const severity = responseJson.errors ? 'error' : 'success';
    this.displaySnackbar(message, severity);
  }
}


handleBrandResponses(apiRequestCallId: string, responseJson: any) {
  if (apiRequestCallId === this.euHomeBrandsApiCallId) {
    this.setState({
      brandsIFollowHome: responseJson.followings.data,
      suggestedBrandsHome: responseJson.suggested_brands.data,
      top6: responseJson.top6.data
    });
  }
}


handleTokenAndFollowResponses(apiRequestCallId: string, responseJson: any) {
  if (apiRequestCallId === this.tokenUpdateApiCallId) {
    this.handleTokenUpdateApiResponse(responseJson);
  }

  if (responseJson && !responseJson.errors && !responseJson.error) {
    if (apiRequestCallId === this.followBrandApiCallId) {
      this.getSuggestedAndFollowBrandHome();
    }
  }
}


displaySnackbar(message: string, severity: 'success' | 'error') {
  this.setState({
    snackbarMessage: message,
    snackbarSeverity: severity,
    openSnackbar: true,
    anchorEl: null,
  });

  setTimeout(() => {
    this.setState({ openSnackbar: false });
  }, 3000);
}
  
  updateToken = () => {
    if (this.state.account) {
      this.tokenUpdateApiCallId = this.makeRequest({
        endpoint: `${configJSON.accountApiEndpoint}/${
          this.state.account.id
        }/get_new_token`,
        method: configJSON.dashboarApiMethodType,
        headers: {
          "Content-Type": configJSON.dashboarContentType,
          token: getToken()
        }
      });
    }
  };

  followBrandApi = (brandId: string) => {
    const token = window.localStorage.getItem(configJSON.EU_AUTH_TOKEN) || ""
    const httpBody = {
      data:{
          attributes:{
              followed_user_id: brandId
          }
      }
    }
    this.followBrandApiCallId = this.makeRequest({
      endpoint: configJSON.followBrandApiEndpoint,
      method: configJSON.httpPostMethod,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token
      },
      body: JSON.stringify(httpBody)
    })
  }

  getDashboardData = () => {
    const token = window.localStorage.getItem("EUAuthToken");
    const baseRoute = this.state.isDashboard ? configJSON.getDashboardData : configJSON.euTrendingpostEndpoint
    if (token) {
      this.dashboardApiCallId = this.makeRequest({
        endpoint: `${baseRoute}?page=${this.state.euHomePageNo}&per_page=${configJSON.POST_PER_PAGE}`,
        method: configJSON.dashboarApiMethodType,
        headers: {
          "Content-Type": configJSON.dashboarContentType,
          token
        }
      });
    }
  };

 getCommentsData = (postId:any) => {
  const token = window.localStorage.getItem("EUAuthToken");
  if (token) {
    this.getPostCommentApiCallId = this.makeRequest({
      endpoint: `bx_block_comments/comments?commentable_id=${postId}&commentable_type=BxBlockPosts::Post&sort=desc`,
      method: 'GET',
      headers: {
        "Content-Type": 'application/json',
        token,
      },
    })
  }
};


  
  
  
  

  handleScrollHomePost = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if(this.state.isEUHomePostLoading) return

    if (scrollHeight - scrollTop <= clientHeight + 10 && this.state.euHomePageNo < this.state.euHomeTotalPages) {
      this.setState(prevState => ({
        euHomePageNo: prevState.euHomePageNo + 1,
        isEUHomePostLoading: true
      }));
    }
  };

  getSuggestedAndFollowBrandHome = async () => {
    const EuToken = await getStorageData("EUAuthToken");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.euHomeBrandsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetEUTrendingBrands
    );
    const header = {
      "Content-Type": configJSON.contentTypeApiGetEUTrendingBrands,
      token: EuToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetEUTrendingBrands
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePostLikes = (postId: string) => {
    const token = window.localStorage.getItem("EUAuthToken");
    const payload = {
      like: {
        likeable_id: postId,
        likeable_type: "BxBlockPosts::Post"
      }
    };
    if (token) {
      this.addLikePostApiCallId = this.makeRequest({
        endpoint: configJSON.addLikeToPostEndPoint,
        method: configJSON.httpPostMethod,
        headers: {
          "Content-Type": configJSON.dashboarContentType,
          token
        },
        body: JSON.stringify(payload)
      });
    }
  };


handleLike = (commentId: string, postId: any) => {
  const token = window.localStorage.getItem("EUAuthToken");
  if (!token) return;

 this.likeCommentApiCallId = this.makeRequest({
    endpoint: "bx_block_like/likes",
    method: "POST",
    headers: {
      "Content-Type": configJSON.dashboarContentType,
      token,
    },
    body: JSON.stringify({
      like: {
        likeable_id: commentId,
        likeable_type: "BxBlockComments::Comment",
      },
    }),
  });

  
  setTimeout(() => {
    this.getCommentsData(postId);
  }, 500);
};


undoLike = (commentId: string, postId: any) => {
  const token = window.localStorage.getItem("EUAuthToken");
  if (!token) return;


  this.UndolikeCommentApiCallId = this.makeRequest({
    endpoint: `bx_block_like/likes/undo_like?likeable_id=${commentId}&likeable_type=BxBlockComments::Comment`,
    method: "DELETE",
    headers: {
      "Content-Type": configJSON.dashboarContentType,
      token,
    },
  });


  setTimeout(() => {
    this.getCommentsData(postId);
  }, 1000);
};



  
  
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    this.setState({ selectedFile: file });
  };

 
 
  

  handlePostComment = (postId: string) => {
    const { localCommentText, uploadedImageFile } = this.state;

    if (localCommentText.trim() || uploadedImageFile) { 
        this.setState((prevState) => ({
            commentTexts: {
                ...prevState.commentTexts,
                [postId]: localCommentText,
            },
            localCommentText: "",  
            uploadedImage: null, 
            uploadedImageFile: null, 
        }));

        const token = window.localStorage.getItem("EUAuthToken");
        if (token) {
            const formData = new FormData();
            formData.append("comment[commentable_type]", "BxBlockPosts::Post");
            formData.append("comment[comment]", localCommentText); 
            formData.append("comment[commentable_id]", postId); 

            if (uploadedImageFile) {
                formData.append("comment[files][]", uploadedImageFile);
            }

            this.addCommentPostApiCallId = this.makeRequest({
                endpoint: configJSON.addCommentToPostEndPoint,
                method: configJSON.httpPostMethod,
                headers: {
                    token,
                },
                body: formData,
            });

            setTimeout(() => {
                this.getCommentsData(postId);
            }, 1000);
        }
    }
};



handleReplySubmit = (postId: number) => {
  const { replyToCommentId, replyText, uploadedReplyImageFile } = this.state;

  const token = window.localStorage.getItem("EUAuthToken");
  if (token && (replyText.trim() || uploadedReplyImageFile)) { 
      const formData = new FormData();
      formData.append("comment[commentable_type]", "BxBlockComments::Comment");
      formData.append("comment[comment]", replyText); 

      if (replyToCommentId !== null) {
          formData.append("comment[commentable_id]", replyToCommentId.toString()); 
      } else {
          console.error("replyToCommentId is null, cannot submit reply.");
          return; 
      }

      if (uploadedReplyImageFile) {
          formData.append("comment[files][]", uploadedReplyImageFile);
      }

      this.addCommentReplyPostApiCallId = this.makeRequest({
          endpoint: configJSON.addCommentToPostEndPoint,
          method: configJSON.httpPostMethod,
          headers: {
              token,
          },
          body: formData,
      });

      this.setState({ replyText: '', replyToCommentId: null, uploadedReplyImageFile: null, uploadedReplyImage: null }); 

      setTimeout(() => {
          this.getCommentsData(postId);
      }, 1000);
  }
};



 


   
  
  

  checkIsVideo = (file: {link : string}) => {
    return (
      (typeof file.link === "string" && file.link.toLowerCase().endsWith(".mp4"))
    );
  };

  handleTokenUpdateApiResponse = (response: { token: string }) => {
    setToken(response.token);
  };

  makeRequest = ({ endpoint, method, headers, body }: APIRequest) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };
}


// Customizable Area End
